<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="card p-5 mx-auto mt-4 text-center">
          <i class="fad fa-gift fa-5x text-primary"></i>
          <h3 class="mt-4">
            Coming Soon!
          </h3>
          <p>
            Komm bald wieder oder schreibe eine E-Mail an <a href="mailto:alexander@fuerst.one">alexander@fuerst.one</a> für
            mehr Infos.
          </p>
          <div v-if="!hideButtons">
            <button class="btn btn-secondary mt-4 mx-1" @click="$router.back()">Zurück zur letzten Seite</button>
            <router-link class="btn btn-primary mt-4 mx-1" to="/overview">Zur Übersicht</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComingSoonBanner',
  components: {},
  directives: {},
  props: {
    hideButtons: Boolean
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.fad {
  transition: color .5s;
}
</style>