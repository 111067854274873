<template>
  <div id="backlog-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <ComingSoonBanner></ComingSoonBanner>
  </div>
</template>

<script>
// @ is an alias to /src
import ComingSoonBanner from "@/components/ComingSoonBanner";

export default {
  name: 'Learnings',
  components: {
    ComingSoonBanner,
  },
  data() {
    return {
    }
  },
  computed: {
    ideas() {
      return this.project.ideas
          .filter(idea => !this.activeTheoryId || idea.theoryId === this.activeTheoryId)
          .map(idea => {
        idea.node = this.project.netNodes.find(n => n.url === idea.nodeUrl);

        if (idea.scores) {
          let scores = idea.scores;
          if (Object.values(scores).filter(x => x === undefined).length === 0) {
            let effort = parseInt(scores.frontendEffort) + parseInt(scores.backendEffort) + parseInt(scores.conceptEffort) + parseInt(scores.otherEffort);
            let impact = parseInt(scores.visualContrast) + parseInt(scores.behaviourContrast) + parseInt(scores.patternsImpact) + parseInt(scores.trafficImpact);

            idea.effort = effort < 8 ? 'Low' : effort > 16 ? 'High' : 'Medium';
            idea.impact = impact < 8 ? 'Low' : impact > 16 ? 'High' : 'Medium';
            idea.scoring = Math.round((impact - effort) * 3.125 + 50);
          }
        } else {
          idea.scoring = "N/A";
        }
        return idea;
      });
    },
  },
  methods: {
  }
}
</script>
